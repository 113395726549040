import { BaseHttpClient } from 'service/http-base/BaseHttpClient';
import type {
  AccountBankResponse,
  ListDocumentRegister,
  ParamAddBankAccount,
  ParamApproveTnc,
  ParamCheckBankAccount,
  ParamCreatePassword,
  ParamLoginAccount,
  ParamRegisterInstitutionalFunder,
  ParamRegisterRetailFunder,
  ParamVerifyRelami,
  RelamiResponse,
  ResendEmailVerificationOrCreatePasswordResponse,
  UserTracking,
  VerifyUserResponse
} from 'types/account';
import { verifyUserRespSchema } from 'types/account';
import type { Response, UserToken } from 'types/common';

export class AccountService extends BaseHttpClient {
  loginAccount(params: ParamLoginAccount): Promise<Response<UserToken>> {
    return this.post('/p2p/api/funder/v3/authenticate', params);
  }

  getAccountTracking(): Promise<Response<UserTracking>> {
    return this.get('/p2p/api/individual/event-tracking/params');
  }

  getDocumentRegister(): Promise<Response<ListDocumentRegister>> {
    return this.get('/p2p/documents/registration-approval');
  }

  postRegisterInstitutionalFunder(
    params: ParamRegisterInstitutionalFunder
  ): Promise<Response<{ email: string }>> {
    return this.post('/p2p/api/funder/v3/registration/institutional', params);
  }

  postRegisterRetailFunder(
    params: ParamRegisterRetailFunder
  ): Promise<Response<{ success: boolean }>> {
    return this.post('/p2p/api/funder/v2.1/registration/user', params);
  }

  postVerifyRelami(
    params: ParamVerifyRelami
  ): Promise<Response<RelamiResponse>> {
    return this.post('/p2p/api/funder/verify-relami', params);
  }

  postApproveTnc(params: ParamApproveTnc): Promise<Response<unknown>> {
    return this.post('/p2p/api/funder/v2.1/registration//tnc/approve', params);
  }

  postResendEmailVerification(params: {
    email: string;
  }): Promise<Response<ResendEmailVerificationOrCreatePasswordResponse>> {
    return this.post(
      '/p2p/api/funder/v2/registration/resend-activation-email',
      params
    );
  }

  postCreatePassword(
    params: ParamCreatePassword
  ): Promise<Response<ResendEmailVerificationOrCreatePasswordResponse>> {
    return this.post('/p2p/api/funder/v2/registration/password', params);
  }

  postActivateAccount(params: {
    key: string;
    deviceToken: string;
  }): Promise<Response<{ success: boolean }>> {
    return this.post(
      `/p2p/api/funder/v2/registration/activate?key=${params.key}&deviceToken=${params.deviceToken}`,
      null,
      { bypassSanitize: true }
    );
  }

  getVerifyUser(): Promise<Response<VerifyUserResponse>> {
    return this.get('/p2p/api/funder/v3/verify-user', {
      responseSchema: verifyUserRespSchema
    });
  }

  postBankAccountCheck(
    params: ParamCheckBankAccount
  ): Promise<AccountBankResponse> {
    return this.post('/p2p/api/funder/v2/profile/bank-account/check', params);
  }

  postAddBankAccount(params: ParamAddBankAccount): Promise<Response<unknown>> {
    return this.post('/p2p/api/funder/v2/profile/bank-account', params);
  }
}
