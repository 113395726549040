export const ROUTER_BASE_URL = '/p2p';

/* All Path should not same with endpoint funder-backend */
export const LOGIN = '/login';
export const VERIFICATION_MFA = '/verification-mfa';
export const ERROR_500 = '/error/500';
export const REGISTER = '/register';
export const REGISTER_FUNDER = '/register/funder';
export const REGISTER_CONFIRMATION_EMAIL = '/register/confirmation-email';
export const REGISTER_CREATE_PASSWORD = '/register/create-password';

export const REGISTER_BENEFICIARY = '/register/beneficiary';
export const REGISTER_ACTIVATION = '/register/activation';
export const REGISTER_FUNDER_ACTIVATION =
  '/public-ctl/funder/activate'; /* cuz on sent email with this path */
export const REGISTER_SUCCESS = '/register/success';
export const RESET_PASSWORD =
  '/public/reset-password'; /* preventif reload page 405 cuz same route with endpoint funder backend */
export const RESET_PASSWORD_EXPIRED =
  '/public/reset-password/expired'; /* preventif reload page 405 cuz same route with endpoint funder backend */
export const RESET_PASSWORD_SET_ID =
  '/id/reset-password/reset'; /* sent link from email /id or /en */
export const RESET_PASSWORD_SET_EN =
  '/en/reset-password/reset'; /* sent link from email /id or /en */

/*
 * the route path in this below need authentication and under funder,
 * naming variable please add prefix funder
 */
export const FUNDER = '/funder';
export const FUNDER_ACCOUNT = '/funder/account';
export const FUNDER_COMPLETE_PROFILE_INSTITUTIONAL =
  '/funder/complete-profile/institutional';
export const FUNDER_ADD_BANK_ACCOUNT = '/funder/add-bank-account';
export const FUNDER_ACCOUNT_PROFILE = '/funder/account/profile';
export const FUNDER_ACCOUNT_VOUCHERS = '/funder/account/vouchers';
export const FUNDER_CAMPAIGN = '/funder/campaign';
export const FUNDER_CAMPAIGN_DETAIL = '/funder/campaign/:id';
export const FUNDER_DASHBOARD = '/funder/dashboard';
export const FUNDER_DOCUMENT = '/funder/document';
export const FUNDER_DOCUMENT_MEMBERSHIP = '/funder/document/membership';
export const FUNDER_DOCUMENT_UMBRELLA_AKAD = '/funder/document/umbrella/akad';
export const FUNDER_DOCUMENT_ADENDUM = '/funder/document/adendum';
export const FUNDER_DOCUMENT_FUNDING_APPROVAL =
  '/funder/document/funding/approval';
export const FUNDER_DOCUMENT_FUNDING_APPROVAL_TITLE =
  '/funder/document/funding/approval/:id';
export const FUNDER_DOCUMENT_TAX_SLIP = '/funder/document/tax/slip';
export const FUNDER_DOCUMENT_NDA = '/funder/document/non-disclosure-agreeement';
export const FUNDER_DOCUMENT_PARTNERSHIP =
  '/funder/document/partnership-agreeement';
export const FUNDER_DOCUMENT_FINANCING_COMMITMENT =
  '/funder/document/financing-commitment';
export const FUNDER_DOCUMENT_AKAD = '/funder/document/akad';
export const FUNDER_DOCUMENT_AKAD_DETAIL = '/funder/document/akad/:id';
export const FUNDER_FAQ_DETAIL = '/funder/help/:id';
export const FUNDER_FUNDING_SIMULATION = '/funder/funding-simulation';
export const FUNDER_HELP = '/funder/help';
export const FUNDER_PORTFOLIO = '/funder/portfolio';
export const FUNDER_PROFILE = '/funder/profile';
export const FUNDER_RDF = '/funder/rdf';
export const FUNDER_SETTING = '/funder/setting';
export const FUNDER_SETIING_ACCOUNT_DELETE = '/funder/setting/account/delete';
export const FUNDER_SETIING_EMAIL = '/funder/setting/email';
export const FUNDER_SETIING_LANGUAGE = '/funder/setting/language';
export const FUNDER_SETIING_PASSWORD = '/funder/setting/password';
export const FUNDER_SETIING_PHONE = '/funder/setting/phone';
export const FUNDER_PAYMENT_VA = '/funder/payment/virtual-account';
export const FUNDER_GENERAL_RIPLAY = '/funder/general-riplay';

/* ECOFIN */
export const ECOFIN = '/beneficiary-ecosystem';
export const ECOFIN_HOME = '/beneficiary-ecosystem/home';
export const ECOFIN_NOTFOUND = '/beneficiary-ecosystem/404';
export const ECOFIN_PROFILE = '/beneficiary-ecosystem/profile';
export const ECOFIN_REGISTER = '/beneficiary-ecosystem/register';
export const ECOFIN_COMPLETEPROFILE = '/beneficiary-ecosystem/complete-profile';
export const ECOFIN_COMPLETE_BUSINESS_DATA =
  '/beneficiary-ecosystem/complete-business-data';
export const ECOFIN_REVIEW_PROFILE_PERSONAL =
  '/beneficiary-ecosystem/review-personal';
export const ECOFIN_REVIEW_PROFILE_BUSINESS =
  '/beneficiary-ecosystem/review-business';
export const ECOFIN_PROFILE_VERIFICATION =
  '/beneficiary-ecosystem/profile-verification';
export const ECOFIN_FINANCING_LIST = '/beneficiary-ecosystem/financing-list';
export const ECOFIN_REVIEW_FINANCING_APPLICATION =
  '/beneficiary-ecosystem/installment-financing-review';
export const ECOFIN_EMAIL_VERIFICATION =
  '/beneficiary-ecosystem/email-verification';
export const ECOFIN_INSTALLMENT_FINANCING_REQUEST =
  '/beneficiary-ecosystem/installment-financing-request';
export const ECOFIN_REMINDER_REPAYMENT =
  '/beneficiary-ecosystem/checkout-repayment';
export const ECOFIN_SUBMIT_PROOF_FUNDING =
  '/beneficiary-ecosystem/submit-proof-funding';
export const AGRI_EXPIRED_LINK = '/beneficiary-ecosystem/expired-link-register';
export const AGRI_REVIEW_PROFILE_REGISTER =
  '/beneficiary-ecosystem/register/review-data';
export const AGRI_FINANCING_REQUEST =
  '/beneficiary-ecosystem/financing-request';
export const AGRI_FINANCING_REVIEW = '/beneficiary-ecosystem/financing-review';
