import { z } from 'zod';

export const CustomerTitleResponseSchema = z.object({
  id: z.string(),
  description: z.string(),
  descriptionEn: z.string(),
  jenisKelamin: z.string().nullable()
});

export const listCustomerTitleSchema = z.array(CustomerTitleResponseSchema);

export type CustomerTitleResponse = z.infer<typeof CustomerTitleResponseSchema>;

export type ListCustomerTitleResponse = z.infer<typeof listCustomerTitleSchema>;

export const masterDataResponseSchema = z.object({
  id: z.string(),
  name: z.string()
});

export const listMasterDataSchema = z.array(masterDataResponseSchema);

export type MasterDataResponse = z.infer<typeof masterDataResponseSchema>;

export type ListMasterDataResponse = z.infer<typeof listMasterDataSchema>;

const childLocationSchema = z
  .object({
    postalCode: z.string()
  })
  .merge(masterDataResponseSchema);

export const listChildLocationSchema = z.array(childLocationSchema);

export type ChildLocationResponse = z.infer<typeof childLocationSchema>;

export type ListChildLocationResponse = z.infer<typeof listChildLocationSchema>;

export const occupationSchema = z.object({
  id: z.number(),
  name: z.string(),
  orderNumber: z.number(),
  key: z.string()
});

export const listOcupationSchema = z.array(occupationSchema);

export type OcupationResponse = z.infer<typeof occupationSchema>;
export type ListOcupationResponse = z.infer<typeof listOcupationSchema>;

export const masterDataWithKeySchema = z
  .object({
    key: z.string()
  })
  .merge(masterDataResponseSchema);

export const listMasterDataWithKeySchema = z.array(masterDataWithKeySchema);

export type MasterDataWithKeyResponse = z.infer<typeof masterDataWithKeySchema>;

export type ListAmendDeedTypeResponse = z.infer<
  typeof listMasterDataWithKeySchema
>;

export type ListNpwpOwnerShipResponse = z.infer<
  typeof listMasterDataWithKeySchema
>;

export const documentSchema = z.object({
  docUrl: z.string(),
  docTitle: z.string(),
  taxNumber: z.string().nullable(),
  taxPeriod: z.string().nullable(),
  taxYear: z.string().nullable(),
  taxYearAndPeriod: z.string().nullable()
});

export const documentFunderSchema = z.object({
  fundingApprovals: z.array(documentSchema),
  manualAkads: z.array(documentSchema),
  membershipAgreement: documentSchema,
  nda: documentSchema,
  pks: documentSchema,
  taxSlips: z.array(documentSchema),
  umbrellaAkads: z.array(documentSchema)
});

export type DocumentFunderResponse = z.infer<typeof documentFunderSchema>;

export const bankSchema = z.object({
  cleringCode: z.string(),
  code: z.string(),
  id: z.string(),
  isntamoneyCode: z.string(),
  name: z.string(),
  rtgsCode: z.string()
});

export const listBankSchema = z.array(masterDataWithKeySchema);

export type ListBankResponse = z.infer<typeof listBankSchema>;

export type BankResponse = z.infer<typeof bankSchema>;

export const generalRiplaySchema = z.object({
  name: z.string(),
  slug: z.string(),
  urlPath: z.string()
});

export const listGeneralRiplaySchema = z.array(generalRiplaySchema);

export type ListGeneralRiplayResponse = z.infer<typeof listGeneralRiplaySchema>;

export const masterDataCompanyDataFormSchema = z.object({
  isLoading: z.boolean(),
  corporates: z.array(masterDataResponseSchema),
  companies: z.array(masterDataResponseSchema),
  provinces: z.array(masterDataResponseSchema),
  businessCategories: z.array(masterDataResponseSchema),
  annualIncomes: z.array(masterDataResponseSchema),
  sourceOfFunds: z.array(masterDataResponseSchema)
});

export type MasterDataCompanyDataForm = z.infer<
  typeof masterDataCompanyDataFormSchema
>;

export const martialStatusSchema = z.object({
  description: z.string(),
  descriptionEn: z.string(),
  descriptionIn: z.string(),
  id: z.string(),
  ordering: z.number()
});

export type MartialStatus = z.infer<typeof martialStatusSchema>;

export const listMartialStatusSchema = z.array(martialStatusSchema);

export type ListMartialStatus = z.infer<typeof listMartialStatusSchema>;

export const educationSchema = z.object({
  description: z.string(),
  descriptionEn: z.string(),
  descriptionIn: z.string(),
  id: z.string(),
  idOjk: z.number()
});

export type Education = z.infer<typeof educationSchema>;

export const listEducationSchema = z.array(educationSchema);

export type ListEducation = z.infer<typeof listEducationSchema>;

export const profileJobSchema = z.object({
  description: z.string(),
  orderNumber: z.number(),
  id: z.number()
});

export type ProfileJob = z.infer<typeof profileJobSchema>;

export const listProfileJobSchema = z.array(profileJobSchema);

export type ListProfileJob = z.infer<typeof listProfileJobSchema>;

export const profileIndustrySchema = z.object({
  financialInstitution: z.string().nullable(),
  id: z.string(),
  name: z.string(),
  nameEn: z.string(),
  nameId: z.string(),
  ordering: z.number(),
  parent: z.string().nullable(),
  retailFunder: z.boolean(),
  retailFunderOrder: z.number()
});

export type ProfileIndustry = z.infer<typeof profileIndustrySchema>;

export const listProfileIndustrySchema = z.array(profileIndustrySchema);

export type ListProfileIndustry = z.infer<typeof listProfileIndustrySchema>;

export const profileMonthlyIncomeSchema = z.object({
  description: z.string(),
  descriptionEn: z.string(),
  descriptionIn: z.string(),
  id: z.string(),
  ordering: z.number()
});

export type ProfileMonthlyIncome = z.infer<typeof profileMonthlyIncomeSchema>;

export const listProfileMontlyIncomeSchema = z.array(
  profileMonthlyIncomeSchema
);

export type ListProfileMonthlyIncome = z.infer<
  typeof listProfileMontlyIncomeSchema
>;

export const profileSourceOfFundSchema = z.object({
  orderNumber: z.number(),
  description: z.string(),
  id: z.string()
});

export type ProfileSourceOfFund = z.infer<typeof profileSourceOfFundSchema>;

export const listProfileSourceOfFundSchema = z.array(profileSourceOfFundSchema);

export type ListProfileSourceOfFund = z.infer<
  typeof listProfileSourceOfFundSchema
>;

export const profileEmergencyContactSchema = z.object({
  description: z.string(),
  descriptionEn: z.string(),
  descriptionId: z.string(),
  id: z.string()
});

export type ProfileEmergencyContact = z.infer<
  typeof profileEmergencyContactSchema
>;

export const listProfileEmergencyContactSchema = z.array(
  profileEmergencyContactSchema
);

export type ListProfileEmergencyContact = z.infer<
  typeof listProfileEmergencyContactSchema
>;

export const profileFundingPurposeSchema = z.object({
  id: z.number(),
  orderNumber: z.number(),
  name: z.string()
});

export type ProfileFundingPurpose = z.infer<typeof profileFundingPurposeSchema>;

export const listProfileFundingPurposeSchema = z.array(
  profileFundingPurposeSchema
);

export type ListProfileFundingPurpose = z.infer<
  typeof listProfileFundingPurposeSchema
>;

export const profilePositionSchema = z.object({
  id: z.number(),
  orderNumber: z.number(),
  name: z.string(),
  key: z.string().nullable()
});

export type ProfilePosition = z.infer<typeof profilePositionSchema>;

export const listProfilePositionSchema = z.array(profilePositionSchema);

export type ListProfilePosition = z.infer<typeof listProfilePositionSchema>;

export const profileExperienceSchema = z.object({
  id: z.number(),
  labelId: z.string(),
  labelEn: z.string(),
  pusdafilCode: z.string(),
  name: z.string()
});

export type ProfileExperience = z.infer<typeof profileExperienceSchema>;

export const listProfileExperienceSchema = z.array(profileExperienceSchema);

export type ListProfileExperience = z.infer<typeof listProfileExperienceSchema>;

export const profileReligionSchema = z.object({
  description: z.string(),
  idOjk: z.number(),
  descriptionEn: z.string(),
  id: z.string()
});

export type ProfileReligion = z.infer<typeof profileReligionSchema>;

export const listProfileReligionSchema = z.array(profileReligionSchema);

export type ListProfileReligion = z.infer<typeof listProfileReligionSchema>;
