import { z } from 'zod';

export interface Response<T> {
  body: T;
  statusCode: number;
  statusMessage: string;
}

export type UserToken = {
  username: string;
  expiresIn: number;
  token: string;
  refreshToken: string;
  userRoleTypeId: number;
  userId: string;
  benefType: string;
};

const errorResponseSchema = z.object({
  errorField: z.string(),
  errorMessage: z.string(),
  errorLongText: z.string()
});

export type ErrorResponseForm = z.infer<typeof errorResponseSchema>;

export const formStatusSchema = z.object({
  isValid: z.boolean(),
  errorFields: z.array(errorResponseSchema),
  rejectedFields: z.array(errorResponseSchema)
});

export type SelectOption = {
  id: string;
  name: string;
};

export type SelectOptionWithNumber = {
  id: number;
  name: string;
};

export type SelectOptionWithKey = SelectOption & {
  key: string;
};

export type SelectOptionChildLocation = SelectOption & {
  postalCode: string;
};

export type MapSelectOption = {
  isLoading: boolean;
  data: SelectOption[];
};

export type MapSelectOptionWithNumber = {
  isLoading: boolean;
  data: SelectOptionWithNumber[];
};

export type MapSelectOptionWithKey = {
  isLoading: boolean;
  data: SelectOptionWithKey[];
};

export type MapSelectOptionChildLocation = {
  isLoading: boolean;
  data: SelectOptionChildLocation[];
};

export type DocumentType =
  | 'ktp'
  | 'npwp'
  | 'selfie'
  | 'pic-poa'
  | 'est-deed'
  | 'sk-menkumham'
  | 'nib'
  | 'siup'
  | 'tdp'
  | 'trx-report'
  | 'company-npwp'
  | 'amend-deed'
  | 'sk-menkumham'
  | 'nda'
  | 'pks'
  | 'skb'
  | 'mgmt-id-card'
  | 'mgmt-npwp'
  | 'char-id-card'
  | 'char-npwp'
  | 'ubo-ktp'
  | 'ubo-passport'
  | 'ubo-support'
  | '';
