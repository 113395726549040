import { BaseHttpClient } from 'service/http-base/BaseHttpClient';
import type { DocumentType, Response } from 'types/common';
import type {
  DocumentFunderResponse,
  ListAmendDeedTypeResponse,
  ListBankResponse,
  ListChildLocationResponse,
  ListCustomerTitleResponse,
  ListEducation,
  ListGeneralRiplayResponse,
  ListMartialStatus,
  ListMasterDataResponse,
  ListNpwpOwnerShipResponse,
  ListOcupationResponse,
  ListProfileEmergencyContact,
  ListProfileExperience,
  ListProfileFundingPurpose,
  ListProfileIndustry,
  ListProfileJob,
  ListProfileMonthlyIncome,
  ListProfilePosition,
  ListProfileReligion,
  ListProfileSourceOfFund
} from 'types/master-data';
import {
  documentFunderSchema,
  listBankSchema,
  listChildLocationSchema,
  listCustomerTitleSchema,
  listEducationSchema,
  listGeneralRiplaySchema,
  listMartialStatusSchema,
  listMasterDataSchema,
  listMasterDataWithKeySchema,
  listOcupationSchema,
  listProfileEmergencyContactSchema,
  listProfileExperienceSchema,
  listProfileFundingPurposeSchema,
  listProfileIndustrySchema,
  listProfileJobSchema,
  listProfileMontlyIncomeSchema,
  listProfilePositionSchema,
  listProfileReligionSchema,
  listProfileSourceOfFundSchema
} from 'types/master-data';

export class MasterService extends BaseHttpClient {
  getCorporateClassification(): Promise<Response<ListMasterDataResponse>> {
    return this.get('/p2p/api/funder/master/v2/corporate/types', {
      responseSchema: listMasterDataSchema
    });
  }

  getCompanyClassification(): Promise<Response<ListMasterDataResponse>> {
    return this.get('/p2p/api/funder/master/v2/company/types', {
      responseSchema: listMasterDataSchema
    });
  }

  getCustomerTitle(): Promise<Response<ListCustomerTitleResponse>> {
    return this.get('/p2p/profile/customer-title', {
      responseSchema: listCustomerTitleSchema
    });
  }

  getLocations(
    locationLevel: string
  ): Promise<Response<ListMasterDataResponse>> {
    return this.get(`/p2p/api/funder/location/level/${locationLevel}`, {
      responseSchema: listMasterDataSchema
    });
  }

  getChildLocation(
    level: string,
    locationId: string
  ): Promise<Response<ListChildLocationResponse>> {
    return this.get(
      `/p2p/api/funder/location/level-parent/${level}/${locationId}`,
      {
        responseSchema: listChildLocationSchema
      }
    );
  }

  getOccupations(): Promise<Response<ListOcupationResponse>> {
    return this.get('/p2p/api/funder/master/v2/occupation/positions', {
      responseSchema: listOcupationSchema
    });
  }

  getDocumentPreview(docType: DocumentType, docId: string): Promise<Blob> {
    return this.get(`/p2p/api/funder/documents/v2/${docType}/${docId}`, {
      isBlobFile: true
    });
  }

  getPostalCode(locationId: string): Promise<Response<string>> {
    return this.get(`/p2p/api/funder/location/post-code/${locationId}`);
  }

  getBusinessCategories(): Promise<Response<ListMasterDataResponse>> {
    return this.get('/p2p/api/funder/master/v2/industries', {
      responseSchema: listMasterDataSchema
    });
  }

  getSubBusinessCategories(
    industryId: string
  ): Promise<Response<ListMasterDataResponse>> {
    return this.get(`/p2p/api/funder/master/v2/industries/${industryId}`, {
      responseSchema: listMasterDataSchema
    });
  }

  getAnnualIncomes(): Promise<Response<ListMasterDataResponse>> {
    return this.get('/p2p/api/funder/master/v2/company/annual-incomes', {
      responseSchema: listMasterDataSchema
    });
  }

  getSourceOfFunds(): Promise<Response<ListMasterDataResponse>> {
    return this.get('/p2p/api/funder/master/v2/company/source-of-funds', {
      responseSchema: listMasterDataSchema
    });
  }

  getAmendmentDeedType(): Promise<Response<ListAmendDeedTypeResponse>> {
    return this.get('/p2p/api/funder/master/v2/company/amend-deed-types', {
      responseSchema: listMasterDataWithKeySchema
    });
  }

  getCharaterTypeShareholders(): Promise<Response<ListMasterDataResponse>> {
    return this.get('/p2p/api/funder/master/v2/character/types', {
      responseSchema: listMasterDataSchema
    });
  }

  getNpwpOwnershipStatus(): Promise<Response<ListNpwpOwnerShipResponse>> {
    return this.get('/p2p/api/funder/master/v2/npwp-ownerships', {
      responseSchema: listMasterDataWithKeySchema
    });
  }

  getCountries(): Promise<Response<ListMasterDataResponse>> {
    return this.get('/p2p/api/funder/master/v2/countries', {
      responseSchema: listMasterDataSchema
    });
  }

  getUBOJobs(): Promise<Response<ListMasterDataResponse>> {
    return this.get('/p2p/api/funder/master/v2/rdl/jobs', {
      responseSchema: listMasterDataSchema
    });
  }

  getUBOMonthlyIncomes(): Promise<Response<ListMasterDataResponse>> {
    return this.get('/p2p/api/funder/master/v2/rdl/monthly-incomes', {
      responseSchema: listMasterDataSchema
    });
  }

  getDocumentList(): Promise<Response<DocumentFunderResponse>> {
    return this.get('/p2p/api/funder/documents', {
      responseSchema: documentFunderSchema
    });
  }

  getDocumentByUrl(url: string): Promise<Blob> {
    return this.get(`/p2p/api/funder/documents/${url}`, {
      isBlobFile: true
    });
  }

  getBanks(): Promise<Response<ListBankResponse>> {
    return this.get('/p2p/api/funder/profile/bank-list', {
      responseSchema: listBankSchema
    });
  }

  getGeneralRiplays(): Promise<Response<ListGeneralRiplayResponse>> {
    return this.get('/p2p/api/funder/documents/v2/general-riplay-products', {
      responseSchema: listGeneralRiplaySchema
    });
  }

  getDocumentRiplayBySlug(slug: string): Promise<Blob> {
    return this.get(`/p2p/api/funder/documents/v2/${slug}`, {
      isBlobFile: true
    });
  }

  getOccupationManagements(): Promise<Response<ListMasterDataResponse>> {
    return this.get(
      '/p2p/api/funder/master/v2/management/occupation/positions',
      {
        responseSchema: listMasterDataSchema
      }
    );
  }

  postAckGeneralRiplayByDocType(docType: string): Promise<Response<unknown>> {
    return this.post(`/p2p/api/funder/documents/v2/${docType}/ack`);
  }

  getMartialStatuses(): Promise<Response<ListMartialStatus>> {
    return this.get('/p2p/api/funder/profile/marital-status', {
      responseSchema: listMartialStatusSchema
    });
  }

  getEducations(): Promise<Response<ListEducation>> {
    return this.get('/p2p/api/funder/profile/education-level', {
      responseSchema: listEducationSchema
    });
  }

  getProfileReligions(): Promise<Response<ListProfileReligion>> {
    return this.get('/p2p/profile/religion', {
      responseSchema: listProfileReligionSchema
    });
  }

  getProfileJobs(): Promise<Response<ListProfileJob>> {
    return this.get('/p2p/api/funder/master/v2/occupation/types', {
      responseSchema: listProfileJobSchema
    });
  }

  getProfileIndustries(): Promise<Response<ListProfileIndustry>> {
    return this.get('/p2p/api/funder/master/v2/occupation/industries', {
      responseSchema: listProfileIndustrySchema
    });
  }

  getProfileMonthlyIncomes(): Promise<Response<ListProfileMonthlyIncome>> {
    return this.get('/p2p/api/funder/profile/monthly-income', {
      responseSchema: listProfileMontlyIncomeSchema
    });
  }

  getProfileSourceOfFunds(): Promise<Response<ListProfileSourceOfFund>> {
    return this.get('/p2p/api/funder/master/v2/funding/sources', {
      responseSchema: listProfileSourceOfFundSchema
    });
  }

  getProfileEmergencyContacts(): Promise<
    Response<ListProfileEmergencyContact>
  > {
    return this.get('/p2p/api/funder/profile/contact-relation', {
      responseSchema: listProfileEmergencyContactSchema
    });
  }

  getProfileFundingPurposes(): Promise<Response<ListProfileFundingPurpose>> {
    return this.get('/p2p/api/funder/master/v2/funding/purposes', {
      responseSchema: listProfileFundingPurposeSchema
    });
  }

  getProfilePositions(): Promise<Response<ListProfilePosition>> {
    return this.get('/p2p/api/funder/master/v2/occupation/positions', {
      responseSchema: listProfilePositionSchema
    });
  }

  getProfileExperience(): Promise<Response<ListProfileExperience>> {
    return this.get('/p2p/api/funder/master/v2/occupation/experiences', {
      responseSchema: listProfileExperienceSchema
    });
  }
}
